
.btnn{
  font-size: 17px;
}

  .MuiButton-label{
    font-size: 11px;
    padding-left: 20px;
    padding-right: 20px;
  }


  .btnn
  {
      position: relative;
  }
  
  .btnn:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FFF;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }
  .btnn.middle:hover:before {
    visibility: visible;
    transform: scaleX(1);
  }