.ejXxNI {
  background-color: rgb(253 216 32) !important;
  box-shadow: 0 0 1px 3px rgb(253 216 1) !important;
}
.carousels {
  width: 80% !important;
}
.center {
  display: flex;
  justify-content: center;
  width: 100% !important;
}
.btnarrows {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
}
.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.xzcEF {
  margin-top: 66px !important;
}
.fyLrfx {
  margin-top: 60px !important;
}
.glAxCh {
  background-color: rgb(0, 21, 45) !important;
}
@media only screen and (max-width: 800px) {
  .carousels {
    width: 83% !important;
  }
}
