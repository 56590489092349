.MuiButton-label{
    font-size: 11px;
}
.MuiPaper-rounded {
    border-radius: 0px !important;
}
.MuiGrid-spacing-xs-2{
    width: 100% !important;
}
.devicer {
    width: 2em;
    display: block;
    height: 5px;
    background-color: #4b4b4d;
    margin: 60px auto 0;
    position: relative;
    right: 47.5%;
    top:2%;
  }
  .devicer1 {
    width: 2em;
    display: inline-block;
    height: 5px;
    background-color: black;
    margin: 60px auto 0;
    position: relative;
    bottom: 10px;
  }
  button:focus {

    outline: 0px auto -webkit-focus-ring-color;
}
.MuiButton-root:hover {
  background-color: orangered;
}


.MuiButton-root:hover {
 
  background-color: orangered;
}
.carousel-indicators{
  display: none
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}