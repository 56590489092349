.carousel {
  width: 100% !important;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: black;
  border-radius: 50px;
}
.carousel-control-next-icon {
  position: relative;
  left: 8em;
}
.carousel-control-prev-icon {
  position: relative;
  right: 8em;
}
.controls-top {
  display: none;
}
@media only screen and (max-width: 1098px) {
  .carousel-control-next-icon {
    position: relative;
    left: 1em;
  }
  .carousel-control-prev-icon {
    position: relative;
    right: 1em;
  }
}
